
















































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'Chem51LCProject2OISafety',
  components: {TableInput},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        safetyTable: [
          {
            chemicalName: '',
            GhsSymbol1: '',
            precautionaryStatement1: '',
            GhsSymbol2: '',
            precautionaryStatement2: '',
          },
        ],
        wasteTable: [
          {
            solidWaste: '',
            liquidWaste: '',
            glassWaste: '',
            trashCan: '',
          },
        ],
      },
      attachments: [] as File[],
      safetyColumns: [
        {label: 'Chemical Name', component: STextarea, prop: 'chemicalName', width: '30%'},
        {label: 'GHS Symbol #1', component: STextarea, prop: 'GhsSymbol1', width: '10%'},
        {
          label: 'Associated Precautionary Statement',
          component: STextarea,
          prop: 'precautionaryStatement1',
          width: '25%',
        },
        {label: 'GHS Symbol #2', component: STextarea, prop: 'GhsSymbol2', width: '10%'},
        {
          label: 'Associated Precautionary Statement',
          component: STextarea,
          prop: 'precautionaryStatement2',
          width: '25%',
        },
      ],
      wasteColumns: [
        {label: 'Solid waste', component: STextarea, prop: 'solidWaste', width: '25%'},
        {label: 'Liquid waste', component: STextarea, prop: 'liquidWaste', width: '25%'},
        {label: 'Glass waste', component: STextarea, prop: 'glassWaste', width: '25%'},
        {label: 'Trash can', component: STextarea, prop: 'trashCan', width: '25%'},
      ],
    };
  },
  methods: {},
  computed: {
    imageName() {
      return '/img/assignments/GHS_Symbols.png';
    },
    solidLiquidWasteImageName() {
      return '/img/assignments/solidLiquidWaste.png';
    },
    glassWasteImageName() {
      return '/img/assignments/glassWaste.png';
    },
    trashCanImageName() {
      return '/img/assignments/trashCan.png';
    },
    attachments(): File[] {
      return [...this.attachments];
    },
  },
});
